import FeatureSnapshot from '../public/images/Areas_Snapshot.png';
import Image from 'next/image';

const LandingFeatureBlock = ({ name, type, title, description, image }) => {
  return (
    <>
      <div className="feature-container">
        <div className="feature-content">
          {type === 'primary' && (
            <div className="feature-branding">
              <h3>{name}</h3>
            </div>
          )}
          <h2>
            {/* {type === 'primary' && (
              <span style={{ color: '#9747ff' }}>{name} →</span>
            )}{' '} */}
            {title}
          </h2>
          <p style={{ paddingBottom: 16 }}>{description}</p>
          {type === 'primary' && (
            <Image
              style={{
                margin: '0 auto',
                paddingTop: '1rem',
                width: '100%',
                height: 'auto',
              }}
              src={image}
              alt={`Snapshot of ${name} feature`}
            />
          )}
        </div>
      </div>
      <style jsx>{`
        .feature-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Layout-listGap, 0.5rem);

          border-radius: 1rem;
          border: 1px solid #3f00c445;
           {
            /* background: var(--alwaysBlack, #000); */
          }
        }

        .feature-branding h3 {
          color: var(--Element-Color-brandColor, #9747ff);
          margin: 0;

          /* Body Bold */
          font-family: Inter;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .feature-content {
          display: flex;
          padding: 1rem 1rem 0rem 1rem;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Layout-listGap, 0.5rem);
          align-self: stretch;
        }

        .feature-content h2 {
          color: #fff;
          text-align: center;
          margin: 0;

          /* H2 */
          font-family: Inter;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .feature-content p {
          margin: 0;
          text-align: left;

          overflow: hidden;
          color: #b2b3bd;
          text-overflow: ellipsis;

          /* Body */
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      `}</style>
    </>
  );
};

export default LandingFeatureBlock;
