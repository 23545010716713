import React from 'react';

import U from './UtilityStyle';

import styles from '../styles/PricingTable.module.scss';

const PricingTable: React.FC = () => {
  return (
    <>
      <U.OnlyMobile>
        <div className={styles.gridContainer}>
          <div className={styles.gridHeader}> </div>
          <div className={styles.gridHeader}>
            <span className={styles.keeper_plan}>Keeper</span>
          </div>
          <div className={styles.gridHeader}>
            <span className={styles.pro_plan}>Pro</span>
          </div>

          <div className={styles.gridRowHeader}>Great for</div>
          <div className={styles.gridItem}>Homeowners, Renters</div>
          <div className={styles.gridItem}>
            ADU Owners, Airbnb Hosts, Landlords
          </div>

          <div className={styles.gridRowHeader}>Price</div>
          <div className={styles.gridItem}>
            <strong>Free</strong>
          </div>
          <div className={styles.gridItem}>
            <strong>$105 / year</strong>
          </div>

          <div className={styles.gridRowHeader}>Buildings</div>
          <div className={styles.gridItem}>
            <strong>1</strong>
          </div>
          <div className={styles.gridItem}>
            <strong>Up to 5</strong>
          </div>

          <div className={styles.gridRowHeader}>Areas</div>
          <div className={styles.gridItem}>Unlimited</div>
          <div className={styles.gridItem}>Unlimited</div>

          <div className={styles.gridRowHeader}>Issues</div>
          <div className={styles.gridItem}>Unlimited</div>
          <div className={styles.gridItem}>Unlimited</div>

          <div className={styles.gridRowHeader}>Inventory</div>
          <div className={styles.gridItem}>Unlimited</div>
          <div className={styles.gridItem}>Unlimited</div>

          <div className={styles.gridRowHeader}>Public Buildings</div>
          <div className={styles.gridItem}>Unlimited</div>
          <div className={styles.gridItem}>Unlimited</div>

          <div className={styles.gridRowHeader}>Co-Owners</div>
          <div className={styles.gridItem}>Unlimited</div>
          <div className={styles.gridItem}>Unlimited</div>
        </div>
        <p style={{ color: 'cyan', fontSize: '1rem', paddingTop: '2rem' }}>
          Are you an architect, builder, or trying to scale your real estate
          footprint?
        </p>
        <p style={{ color: 'cyan', fontSize: '1rem' }}>
          <strong>Grow your business with Minicastle.</strong>
        </p>
        <p style={{ color: 'white', fontSize: '1rem' }}>
          <strong>
            <a
              style={{ color: 'white' }}
              href="https://9hdmpr86ftw.typeform.com/to/dnmuqgnO"
              target="_blank"
            >
              Contact us
            </a>{' '}
            for a custom plan and demo.
          </strong>
        </p>
      </U.OnlyMobile>
      <div style={{ maxWidth: '73.125rem', margin: '0 auto' }}>
        <U.OnlyDesktop>
          <div className={styles.gridContainer}>
            <div className={styles.gridHeader}> </div>
            <div className={styles.gridHeader}>
              <span className={styles.keeper_plan}>Keeper</span>
            </div>
            <div className={styles.gridHeader}>
              <span className={styles.pro_plan}>Pro</span>
            </div>
            <div className={styles.gridHeader}>Custom</div>

            <div className={styles.gridRowHeader}>Great for</div>
            <div className={styles.gridItem}>Homeowners, Renters</div>
            <div className={styles.gridItem}>
              Airbnb Hosts, ADU Owners, Landlords
            </div>
            <div className={styles.gridItem}>
              Architects, Builders, Retailers, Franchises
            </div>

            <div className={styles.gridRowHeader}>Price</div>
            <div className={styles.gridItem}>
              <strong>Free</strong>
            </div>
            <div className={styles.gridItem}>
              <strong>$105 / year</strong>
            </div>
            <div className={styles.gridItem}>
              <strong>
                <a
                  style={{ color: 'white' }}
                  href="https://9hdmpr86ftw.typeform.com/to/dnmuqgnO"
                  target="_blank"
                >
                  Contact us
                </a>
              </strong>
            </div>

            <div className={styles.gridRowHeader}>Buildings</div>
            <div className={styles.gridItem}>
              <strong>1</strong>
            </div>
            <div className={styles.gridItem}>
              <strong>Up to 5</strong>
            </div>
            <div className={styles.gridItem}>
              <strong>Unlimited</strong>
            </div>

            <div className={styles.gridRowHeader}>Areas</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>

            <div className={styles.gridRowHeader}>Issues</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>

            <div className={styles.gridRowHeader}>Inventory</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>

            <div className={styles.gridRowHeader}>Public Buildings</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>

            <div className={styles.gridRowHeader}>Co-Owners</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>
            <div className={styles.gridItem}>Unlimited</div>
          </div>
        </U.OnlyDesktop>
      </div>
    </>
  );
};

export default PricingTable;
